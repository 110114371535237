import './App.css';
import'../node_modules/bootstrap/dist/css/bootstrap.min.css';
import'../node_modules/bootstrap/dist/js/bootstrap.bundle.min.js';
import Navbar from './components/Navbar.js'; 
import Home from './components/Home.js';
import Register from './components/Register.js';
//import {Switch,Route} from "react-router-dom";
import { BrowserRouter, Routes ,Route } from 'react-router-dom';
import NotFound from './components/NotFound.js';
import Edit from './components/Edit.js';
import Details from './components/Details.js';
function App() {
  return (
    <>
  <Navbar />
  
  <Routes>
  <Route path="/" element={<Home />} />
  <Route path="/register" element={<Register />} />
  <Route path="/edit/:id" element={<Edit />} />
  <Route path="/view/:id" element={<Details />} />




  {/* Error route */}
  <Route path="*" element={<NotFound />} />
</Routes>


    </>
    
  );
}

export default App;
