import React, { useState, useEffect } from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import EmailIcon from "@mui/icons-material/Email";
import CallIcon from "@mui/icons-material/Call";

import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { useParams } from "react-router-dom";
import { NavLink } from "react-router-dom";
import { useNavigate  } from "react-router-dom";


const Details = () => {
  const { id } = useParams("");
  //console.log (id);

const navigate=useNavigate ();


  const [getuserdata, setuserdata] = useState([]);
  console.log(getuserdata);
  const getdata = async (e) => {
    const res = await fetch(`/inddata/${id}`, {
      method: "GET",
      headers: {
        "content-Type": "application/json",
      },
    });

    const data = await res.json();
    console.log(data);
    if (res.status === 422 || !data) {
      alert("error");
      console.log("error");
    } else setuserdata(data[0]);
    console.log("Get Data Success");
  };

  useEffect(() => {
    getdata();
  }, [id]);



  const deleteuser = async (id) => {
    //console.log(id)
    const res2 = await fetch(`/deletedata/${id}`, {
      method: "DELETE",
      headers: {
        "content-Type": "application/json",
      },
    });

    const deletedata = await res2.json();
     //console.log(deletedata)
    if (res2.status === 422 || !deletedata) {
      alert("error");
      
    } else {
      alert(" Data Deleted");
     navigate("/");
    }
  };









  return (
    <div className="container mt-3">
      <h1 style={{ fontWeight: 400 }}> Welcome <span> {getuserdata.name} </span> </h1>

      <Card   variant="outlined" sx={{ maxWidth: 600 } }>
        <CardContent>
          <div className="d-flex flex-row-reverse">


          <NavLink to={`../edit/${id}`} >
            <button className="btn btn-primary mx-4">
            <EditIcon />
            </button></NavLink>


            <button className="btn btn-warning" onClick={()=>deleteuser(id)}>
              <DeleteIcon />
            </button>


            
          </div>
          <div className="row">
            <div className="left_view col-lg-6 col-md-6 col12">
              <img src="/profile.png" style={{ width: 50 }} alt="" />

              <h3 className="mt-3">
                Name : <span> {getuserdata.name} </span>
              </h3>
              <p>
                <EmailIcon />
                E-Mail : <span> {getuserdata.email} </span>
              </p>
            </div>
            <div className="right_view col-lg-6 col-md-6 col12">
              <p className="mt-5">
                <CallIcon />
                Contact : <span> {getuserdata.mobile} </span>
              </p>
            </div>

            <div className="d-flex flex-row-reverse mt-4"><NavLink to="/" > Home </NavLink></div>
     
          </div>
        </CardContent>
       
      </Card>

      

    </div>
    
  );
};

export default Details;
