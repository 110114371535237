import React, { useEffect, useState } from "react";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import PrintIcon from '@mui/icons-material/Print';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import SendIcon from '@mui/icons-material/Send';
import EmailIcon from '@mui/icons-material/Email';


import { NavLink } from "react-router-dom";
const Home = () => {

const printHandel = () => {


 //console.log('print');  
 //let printContents = document.getElementById('tblOnly');
 //let originalContents = document.body.innerHTML;
 //document.body.innerHTML = printContents;
 //window.print();
//document.body.innerHTML = originalContents; 








 window.print();
};


  const [getuserdata, setuserdata] = useState([]);

  const getdata = async (e) => {
    const res = await fetch("/getdata", {
      method: "GET",
      headers: {
        "content-Type": "application/json",
      },
    });
    const data = await res.json();
    // console.log(data)
    if (res.status === 422 || !data) {
      alert("error");
      console.log("error");
    } else setuserdata(data);
    //console.log("Get Data Success");
  };
  useEffect(() => {
    getdata();
  }, []);

  const deleteuser = async (id) => {
    //console.log(id)
    const res2 = await fetch(`/deletedata/${id}`, {
      method: "DELETE",
      headers: {
        "content-Type": "application/json",
      },
    });

    const deletedata = await res2.json();
     //console.log(deletedata)
    if (res2.status === 422 || !deletedata) {
      alert("error");
      
    } else {
      alert(" Data Deleted");
      getdata();
    }
  };

  return (
    <div className="mt-5">
      <div className="container">
        <div className="add_button mt-2 mb-3">
          <NavLink to="/register" className="btn btn-primary">
            Add Data
          </NavLink>
        </div>

        <table className="table" id="tblOnly">
          <thead key="uniqueId1">
            <tr className="table-dark">
              <th scope="col">Name</th>
              <th scope="col">E-Mail</th>
              <th scope="col">Mobile</th>
              <th scope="col"></th>
            </tr>
          </thead>
          <tbody>
            {getuserdata.map((element, id) => {
              return (
                <>
                  <tr>
                    {/* <th scope="row"></th> */}

                    <td>{element.name}</td>
                    <td>{element.email} </td>
                    <td>{element.mobile}</td>
                    <td className="d-flex justify-content-between">
                      {/* <button className='btn btn-success'><i class="fa-solid fa-eye"></i></button>
        <button className='btn btn-primary'><i class="fa-solid fa-pen"></i></button>
        <button className='btn btn-warning'><i class="fa-solid fa-trash"></i></button> */}
                      <NavLink to={`view/${element.id}`}>
                        {" "}
                        <button className="btn btn-success">
                          <RemoveRedEyeIcon />
                        </button>{" "}
                      </NavLink>
                      <NavLink to={`edit/${element.id}`}>
                        <button className="btn btn-primary">
                          <EditIcon />
                        </button>
                      </NavLink>
                      {/* <NavLink to={`delete/${element.id}`}> <button className='btn btn-warning'><DeleteIcon /></button> </NavLink> */}

                      <button className="btn btn-warning" onClick={()=>deleteuser(element.id)}>
                        <DeleteIcon />
                      </button>
                    </td>
                  </tr>
                </>
              );
            })}
          </tbody>
        </table>
        <div className="d-flex flex-row-reverse">
                   <NavLink >
                         


                      <NavLink >
                        <button className="btn btn-primary">
                          <SendIcon />
                        </button>
                      </NavLink>  


                      <NavLink >
                        <button className="btn btn-success">
                          <FileDownloadIcon />
                        </button>
                      </NavLink>    

                      <button className="btn btn-warning" onClick={printHandel}>
                          <PrintIcon />
                        </button>
                      </NavLink>  
                     

        </div>

        
      </div>

      

    
    </div>
  );
};

export default Home;
