import React, { useState } from "react";
import {NavLink ,useNavigate} from "react-router-dom";


const Register = () => {
  const navigate=useNavigate();


  const [inpvalue, setINP] = useState({
    name: "",
    email: "",
    mobile: "",
    
  });

  const setdata = (e) => {
    console.log(e.target.value);
    const { name, value } = e.target;
    setINP((preval) => {
      return {
        ...preval,
        [name]: value,
      };
    });
  };

  



  const addInpdata = async (e) => {
    e.preventDefault();
    const { name, email, mobile } = inpvalue;

    const res = await fetch("/create", {
      method: "POST",
      headers: {
        "content-Type": "application/json",
      },
      body: JSON.stringify({
        name,
        email,
        mobile,
      }),
    });

    const data = await res.json();
    console.log(data);
    if (res.status === 422 || !data) {
      alert("error");
       } else {
      alert("Data Added");
     
     navigate("/")
      //window.location.reload();
    };
    
  };

  
  
 
  return (
    <div className="container">
      <form className="mt-5">
        <div className="row">
          <div className="mb-3  col-lg-6 col-md-6 col-12">
            <label for="exampleInputEmail1" className="form-label">
              Name
            </label>
            <input
              type="text"
              className="form-control"
              id="exampleInputEmail1"
              name="name"
              value={inpvalue.name}
              onChange={setdata}
            />
          </div>
          <div className="mb-3 col-lg-6 col-md-6 col-12">
            <label for="exampleInputPassword1" className="form-label">
              E-Mail
            </label>
            <input
              type="email"
              value={inpvalue.email}
              onChange={setdata}
              name="email"
              className="form-control"
              id="exampleInputPassword1"
            />
          </div>
          <div className="mb-3 col-lg-6 col-md-6 col-12">
            <label for="exampleInputPassword1" className="form-label">
              Mobile
            </label>
            <input
              type="text"
              onChange={setdata}
              value={inpvalue.mobile}
              name="mobile"
              className="form-control"
              id="exampleInputPassword1"
            />
          </div>
         

          <button
            type="submit"
            onClick={addInpdata}
            className="btn btn-primary"


          >
            Submit
          </button>
        </div>
      </form>
      <div className="d-flex flex-row-reverse mt-4">
        <NavLink to="/"> Home </NavLink>
      </div>
    </div>
  );
};

export default Register;
