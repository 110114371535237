import React, { useState , useEffect} from "react";
import { NavLink } from "react-router-dom";
import { useParams,useNavigate } from "react-router-dom";
const Edit = () => {


  const { id } = useParams("");
 
const navigate=useNavigate();

  const [inpvalue, setINP] = useState({
    name: "",
    email: "",
    mobile: "",
    
  });


  const setdata = (e) => {
    //console.log(e.target.value);
    const { name, value } = e.target;
    setINP((preval) => {
      return {
        ...preval,
        [name]: value,
      };
    });
  };





  
  const getdata = async (e) => {
    const res = await fetch(`/inddata/${id}`, {
      method: "GET",
      headers: {
        "content-Type": "application/json",
      },
    });

    const data = await res.json();
    console.log(data);
    if (res.status === 422 || !data) {
      alert("error");
      console.log("error");
    } else setINP(data[0]);
    console.log("Get Data Success");
  };

  useEffect(() => {
    getdata();
  }, [id]);

//`/updatedata/${id}`

  const updateuser= async(e)=>{
e.preventDefault();

const { name, email, mobile } = inpvalue;
const res2=await fetch(`/updatedata/${id}`,{
  method: "PATCH",
  headers: {
    "content-Type": "application/json",
  },

  body: JSON.stringify({
    name,
    email,
    mobile,
  }),

})

const data2 = await res2.json();
console.log(data2);
if (res2.status === 422 || !data2) {
   alert("Fill the Data");
} else {
  alert("Data Updated");
  navigate("/");
};

  }



 
  return (
    
    <div className="container">
   
    <form className="mt-5">
      <div className="row">
        <div className="mb-3  col-lg-6 col-md-6 col-12">
          <label for="exampleInputEmail1" className="form-label">
            Name
          </label>
          <input
            type="text"
            className="form-control"
            value={inpvalue.name}
            onChange={setdata}
           id="exampleInputEmail1"
            name="name" 
            
          />
        </div>
        <div className="mb-3 col-lg-6 col-md-6 col-12">
          <label for="exampleInputPassword1" className="form-label">
            E-Mail
          </label>
          <input
            type="email"
            value={inpvalue.email}
            onChange={setdata}
            name="email"
            className="form-control"
            id="exampleInputPassword1"
          />
        </div>
        <div className="mb-3 col-lg-6 col-md-6 col-12">
          <label for="exampleInputPassword1" className="form-label">
            Mobile
          </label>
          <input
            type="text"
            onChange={setdata}
            value={inpvalue.mobile}
            name="mobile"
            className="form-control"
            id="exampleInputPassword1"
          />
        </div>
        
        <button type="submit"  onClick={updateuser} className="btn btn-primary">
          Update Data
        </button>
      </div>
    </form>
<div className="d-flex flex-row-reverse mt-4">
<NavLink to="/"> Home </NavLink>
</div>
    

  </div>




  )
}

export default Edit