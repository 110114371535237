import React from 'react'

function NotFound() {
  return (
    <div>
        Page Not Found
        </div>
  )
}

export default NotFound